import React, { Component }  from 'react';
import '../helpers/suppress-install-prompt';
import Layout from "../components/Layout";
import SEO from "../components/seo";
import Hero from "../components/PyramidEn/Hero";
import Pyramid1 from "../components/PyramidEn/Pyramid1";
import Pyramid2 from "../components/PyramidEn/Pyramid2";
import Pyramid3 from "../components/PyramidEn/Pyramid3";
import Pyramid4 from "../components/PyramidEn/Pyramid4";
import Pyramid5 from "../components/PyramidEn/Pyramid5";
import Pyramid6 from "../components/PyramidEn/Pyramid6";
import Pyramid7 from "../components/PyramidEn/Pyramid7";
import Footer from "../components/PyramidEn/Footer";
// import 'url-search-params-polyfill';
import { graphql } from "gatsby";
import OneLinkUrlGenerator from "../helpers/onelink-smart-script";

class Page extends Component {
    constructor(props) {
        super(props);
        this.state = { url: ''};
    }

    async componentDidMount(){
        this.buildURLfromQueryString();
    }

    buildURLfromQueryString(){
        // const urlParams = new URLSearchParams(global.location.search);    
        // const utm_campaign = urlParams.has('utm_campaign') ? `campaign=${encodeURIComponent(urlParams.get('utm_campaign'))}` : "";
        // const utm_term = urlParams.has('utm_term') ? `&adgroup=${encodeURIComponent(urlParams.get('utm_term'))}` : "";
        // const install_callback = urlParams.has('install_callback') ? `&install_callback=${encodeURIComponent(urlParams.get('install_callback'))}` : "";
        // const click_callback = urlParams.has('click_callback') ? `&click_callback=${encodeURIComponent(urlParams.get('click_callback'))}` : "";
        // const event_callback_fn1saf = urlParams.has('event_callback_fn1saf') ? `&event_callback_fn1saf=${encodeURIComponent(urlParams.get('event_callback_fn1saf'))}` : "";
        
        // const PYRAMID_RICH_URL = `https://app.adjust.com/jp0rrqi?${utm_campaign}${utm_term}${install_callback}${click_callback}${event_callback_fn1saf}`;

        // this.setState({url: PYRAMID_RICH_URL});


        const onelinkGenerator =  new OneLinkUrlGenerator({
            oneLinkURL: "https://diet-training.onelink.me/17FA/",
            pidKeysList: ['af_pid', 'utm_source'],
            campaignKeysList: ['af_c', 'utm_campaign'],
            pidOverrideList: {
                twitter: 'twitter_out',
                snapchat: 'snapchat_out'
            }
        });

        onelinkGenerator.setCustomParameter("af_click_lookback", "af_click_lookback");
        onelinkGenerator.setCustomParameter("clickid", "clickid");
        onelinkGenerator.setCustomParameter("af_siteid", "af_siteid");
        onelinkGenerator.setCustomParameter("af_cost_model", "af_cost_model");
        onelinkGenerator.setCustomParameter("af_cost_value", "af_cost_value");
        onelinkGenerator.setCustomParameter("af_cost_currency", "af_cost_currency");

        let url = onelinkGenerator.generateUrl();
        if(!url){
            url = "https://diet-training.onelink.me/17FA/";
        }

        this.setState({url});
    }

    render(){
        return (
            <Layout
                page="pyramid"
                showMenu={false}
                showFooter={false}
                showExitup={false}
            >
                <SEO
                    title="Pyramid"
                >
                    <meta name="robots" content="noindex,nofollow" />
                </SEO>
                <Hero hero={this.props.data.hero.childImageSharp.fluid} />
                <Pyramid1 url={this.state.url} />
                <Pyramid2 />
                <Pyramid3 />
                <Pyramid4 url={this.state.url} />
                <Pyramid5 />
                <Pyramid6 url={this.state.url} />
                <Pyramid7
                    phones={this.props.data.phones.childImageSharp.fluid}
                    url={this.state.url}
                />
                <Footer />
            </Layout> 
        );    
    }
}

export default Page;

export const query = graphql`
    query {

        signet: file(relativePath: { eq: "icons/sygnet.png" }) {
            ...fixedImage62
        }

        hero: file(relativePath: { eq: "pyramid/hero.jpg" }) {
            ...fluidImage960
        }

        phones: file(relativePath: { eq: "pyramid/phones-en.png" }) {
            ...fluidImage960
        }
    }
`;
